.socialMediaContainer {
  width: 100vw;
  height: auto;
  padding: 100px 20px;
  background-color: #0e0e2c;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    padding: 0;
  }

  .socialMediaBox {
    padding: 50px 110px;
    margin-right: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 250px;

    @media only screen and (max-width: 1250px) {
      padding: 25px 60px;
    }

    @media only screen and (max-width: 850px) {
      padding: 35px 30px;
      margin: 0;
    }

    .total {
      margin-left: 25px;

      @media only screen and (max-width: 1250px) {
        margin: 0;
      }
    }
    .count {
      font-size: 55px;
      color: white;

      @media only screen and (max-width: 1250px) {
        font-size: 35px;
      }
    }

    h2 {
      font-weight: 800;
      margin-left: 5px;
      color: white;
      font-size: 30px;

      @media only screen and (max-width: 1250px) {
        font-size: 20px;
      }
    }
  }
}
