.streamContainer {
  height: auto;
  width: 100vw;
  background-color: #0e0e2c;
  padding: 100px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 933px) {
    padding: 100px 0px;
  }

  .imgContainer {
    img {
      margin-bottom: 20px;
      width: 450px;
      height: 50px;

      @media only screen and (max-width: 933px) {
        width: 350px;
        height: 30px;
        margin-bottom: 0px;
      }
    }
  }

  .streamWrapper {
    width: 900px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    .youtube {
      @media only screen and (max-width: 933px) {
        width: 350px;
        height: 250px;
      }
    }
  }
}
