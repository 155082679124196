.aboutContainer {
  width: 100vw;
  height: auto;
  background-color: #0e0e2c;
  top: 0;
  padding: 100px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0px;
  }

  .leftAbout {
    margin-left: 390px;
    padding: 100px 0;
    width: 50%;

    @media only screen and (max-width: 1600px) {
      margin-left: 350px;
      padding: 100px 0;
    }

    @media only screen and (max-width: 1400px) {
      margin-left: 300px;
      padding: 100px 0;
    }

    @media only screen and (max-width: 1200px) {
      margin-left: 200px;
      padding: 100px 0;
    }

    @media only screen and (max-width: 1000px) {
      margin-left: 100px;
      padding: 100px 0;
    }

    @media only screen and (max-width: 768px) {
      padding: 0 0;
      margin-top: 50%;
      margin-left: 0px;
      text-align: center;
      width: 80%;
    }

    h2 {
      color: white;
      font-size: 70px;
      margin-bottom: 10px;

      @media only screen and (max-width: 1200px) {
        font-size: 50px;
      }
    }
    .aboutP {
      color: white;
      font-size: 20px;

      @media only screen and (max-width: 1200px) {
        font-size: 15px;
      }
    }
  }

  .rightAbout {
    margin-top: 50px;
    margin-right: 200px;
    margin-left: 150px;
    width: 50%;

    @media only screen and (max-width: 1500px) {
      margin-top: 100px;
    }

    @media only screen and (max-width: 1200px) {
      margin-top: 100px;
      margin-right: 100px;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 0;
      margin-right: 0;
    }

    .burakAboutImg {
      width: 275px;

      @media only screen and (max-width: 1200px) {
        width: 200px;
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
