.mainContainer {
  width: 100vw;

  .navbarContainer {
    height: 200px;

    @media only screen and (max-width: 950px) {
      height: 100px;
    }

    .Wrapper {
      padding: 0px 20px;
      align-items: center;
      width: 100%;
      display: flex;
      justify-content: space-around;

      ul {
        display: flex;
        list-style: none;
        align-items: center;
        z-index: 10;

        img {
          margin-right: 60px;
          width: 200px;

          @media only screen and (max-width: 950px) {
            width: 100px;
          }
        }

        li {
          margin-right: 70px;
          font-size: 18px;
          color: #fff;

          @media only screen and (max-width: 768px) {
            display: none;
          }

          a {
            font-size: inherit;
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }

  .slider {
    top: 0;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease;


    @media only screen and (max-width: 933px) {
      margin-top: 0;
    }

    .container {
      width: 100vw;
      align-items: center;
      justify-content: center;
      display: flex;

      .item {
        width: 900px;
        height: 910px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 933px) {
          align-items: start;
          margin-top: 150px;
        }

        @media only screen and (max-width: 768px) {
          width: 450px;
          height: 320px;
        }
        
        @media only screen and (max-width: 468px) {
          width: 450px;
          height: 320px;
        }

        .youtube {
          @media only screen and (max-width: 933px) {
            width: 550px;
            height: 420px;
          }

          @media only screen and (max-width: 768px) {
            width: 450px;
            height: 320px;
          }

          @media only screen and (max-width: 468px) {
            width: 350px;
            height: 320px;
          }
        }
      }
    }
  }

  .logoContainer {
    margin-top: 400px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 0.95s ease;


    @media only screen and (max-width: 933px) {
      margin-top: 380px;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 310px;
    }

    .logo {
      width: 100vw;
      justify-content: center;
      align-items: center;
      display: flex;

      .imgItem {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 450px;

          @media only screen and (max-width: 933px) {
            width: 300px;
          }
        }
      }
    }
  }

  .arrow {
    height: 100px;
    position: absolute;
    cursor: pointer;

    @media only screen and (max-width: 933px) {
      height: 70px;
    }

    &.left {
      left: 200px;
      top: 500px;
      transform: rotate(-180deg);

      @media only screen and (max-width: 1512px) {
        left: 200px;
        top: 700px;
      }

      @media only screen and (max-width: 933px) {
        top: 570px;
        left: 200px;
      }

      @media only screen and (max-width: 768px) {
        top: 470px;
        left: 50px;
      }

      @media only screen and (max-width: 468px) {
        top:500px;
        left: 0px;
      }
    }

    &.right {
      top: 500px;
      right: 200px;

      @media only screen and (max-width: 1512px) {
        top: 700px;
        right: 200px;
      }

      @media only screen and (max-width: 933px) {
        top: 570px;
        right: 200px;
      }

      @media only screen and (max-width: 768px) {
        top: 470px;
        right: 50px;
      }

      @media only screen and (max-width: 468px) {
        top: 500px;
        right: 0px;
      }
    }
  }
}
