@import url("https://fonts.googleapis.com/css?family=Montserrat:100");

html,
body,
h1 {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;

  .app {
    background: rgb(10, 10, 10);
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(10, 10, 10, 0.6), rgba(0, 0, 0, 0.9)),
      repeating-linear-gradient(
        0,
        transparent,
        transparent 2px,
        black 3px,
        black 3px
      ),
      url("https://wallpaper.dog/large/5502365.jpg");
    background-size: cover;
    background-position: center;
    z-index: 1;
    transition: all 1s ease-in-out;

    &.active {
      height: 0;
    }

    #wrapper {
      text-align: center;

      @mixin glitchCopy {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .glitch {
        position: relative;
        color: white;
        font-size: 4em;
        letter-spacing: 0.5em;
        cursor: pointer;
        animation: glitch-skew 1s infinite linear alternate-reverse;


        &::before {
          @include glitchCopy;
          left: 2px;
          text-shadow: -2px 0 #ff00c1;
          clip: rect(44px, 450px, 56px, 0);
          animation: glitch-anim 5s infinite linear alternate-reverse;
        }

        &::after {
          @include glitchCopy;
          left: -2px;
          text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
          animation: glitch-anim2 1s infinite linear alternate-reverse;
        }
      }

      @keyframes glitch-anim {
        $steps: 20;
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(random(100) + px, 9999px, random(100) + px, 0);
            transform: skew((random(100) / 100) + deg);
          }
        }
      }

      @keyframes glitch-anim2 {
        $steps: 20;
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(random(100) + px, 9999px, random(100) + px, 0);
            transform: skew((random(100) / 100) + deg);
          }
        }
      }

      @keyframes glitch-skew {
        $steps: 10;
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            transform: skew((random(10) - 5) + deg);
          }
        }
      }

      .sub {
        color: rgb(100, 220, 220);
        letter-spacing: 1em;
        font-size: 24px;
      }
    }
  }
}
