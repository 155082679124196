.sponsorVideoContainer {
  width: 100vw;
  height: auto;
  background-color: #0e0e2c;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 950px) {
    flex-direction: column;
    padding: 0;
  }

  .sponsorVideoWrapper {
    background-color: #0b0031;
    text-align: center;
    margin: 50px;
    width: 450px;
    height: 320px;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease;

    @media only screen and (max-width: 1550px) {
      width: 400px;
    }

    @media only screen and (max-width: 1400px) {
      width: 350px;
    }

    @media only screen and (max-width: 1200px) {
      width: 300px;
    }

    @media only screen and (max-width: 1000px) {
      width: 250px;
      height: 200px;
    }

    @media only screen and (max-width: 950px) {
      margin-top: 20px;
      width: 100%;
    }

    .youtube {
      @media only screen and (max-width: 1550px) {
        width: 400px;
      }

      @media only screen and (max-width: 1400px) {
        width: 350px;
      }

      @media only screen and (max-width: 1200px) {
        width: 300px;
      }

      @media only screen and (max-width: 950px) {
        height: 30%;
        width: 80%;
      }
    }

    &:hover {
      clip-path: polygon(0 0, 90% 0, 100% 15%, 100% 100%, 10% 100%, 0 85%);
      transform: scale(1.1);
    }

    .sponsorVideoTitle {
      margin-top: 15px;

      @media only screen and (max-width: 950px) {
        margin-top: 5px;
      }

      span {
        font-size: 27px;
        color: white;

        @media only screen and (max-width: 1000px) {
          font-size: 20px;
          color: white;
        }
      }
    }
  }
}
